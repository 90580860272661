import { Overlay } from '@react-aria/overlays';
import {
  ModalProvider,
  ModalProps,
  useModal,
  useDisclosure,
} from '@nextui-org/react';
import { AnimatePresence } from 'framer-motion';

function AnimatedModal(props: ModalProps & { onExitComplete?: () => void }) {
  const { children, onExitComplete, ...otherProps } = props;
  const context = useModal({ ...otherProps });

  const overlay = (
    <Overlay portalContainer={context.portalContainer}>{children}</Overlay>
  );

  return (
    <ModalProvider value={context}>
      <AnimatePresence onExitComplete={onExitComplete}>
        {context.isOpen ? overlay : null}
      </AnimatePresence>
    </ModalProvider>
  );
}

import { Dispatch, SetStateAction, useEffect } from 'react';
import { ModalContent } from '@nextui-org/react';
import { ObjektModal } from '../components/objekt-modal';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';
import { Objekt } from '../cosmo';

export default function ObjektDetail() {
  const navigate = useNavigate();
  let { collectionId } = useParams() as { collectionId: string };
  let { isOpen, onOpenChange } = useDisclosure({
    defaultOpen: true,
  });
  useEffect(() => {
    console.log('collectionId', collectionId);
    if (!isOpen) {
      onOpenChange();
    }
  }, [collectionId]);

  // FIXME: I'm not sure how to reliably go back to the previous page
  let {
    previousPath,
    objekts,
    ownedObjekts,
    selectedObjekts,
    setSelectedObjekts,
  } = useOutletContext() as {
    previousPath: string;
    objekts: Objekt[];
    ownedObjekts: Objekt[];
    selectedObjekts: Objekt[];
    setSelectedObjekts: Dispatch<SetStateAction<Objekt[]>>;
  };

  let objekt = objekts.find(objekt => objekt.collectionId === collectionId);
  ownedObjekts = ownedObjekts.filter(
    ownedObjekt => ownedObjekt.collectionId === collectionId,
  );
  if (!objekt) {
    return <div>Objekt not found</div>;
  }
  return (
    <AnimatedModal
      size='3xl'
      scrollBehavior='outside'
      className='mb-1'
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onExitComplete={() => {
        navigate(previousPath);
      }}
    >
      <ModalContent>
        {onClose => (
          <ObjektModal
            objekt={objekt}
            onClose={onClose}
            ownedObjekts={ownedObjekts}
            selectedObjekts={selectedObjekts}
            setSelectedObjekts={setSelectedObjekts}
          />
        )}
      </ModalContent>
    </AnimatedModal>
  );
}
