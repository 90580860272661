import {
  Chip,
  ModalHeader,
  ModalBody,
  Tabs,
  Tab,
  Checkbox,
  Input,
  Button,
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  ModalFooter,
  Spinner,
} from '@nextui-org/react';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { cosmoClient, Objekt } from '../cosmo';
import { ArrowLeftIcon, ArrowRightIcon, HashIcon, SendIcon } from '../icons';
import { polarisClient } from '../polaris';
import { getNicknames, getTimestamps } from '../routes/objekts';
import { getShortCollectionId } from '../utils';

export function ObjektModal({
  objekt,
  onClose,
  ownedObjekts,
  selectedObjekts,
  setSelectedObjekts,
}: {
  objekt: Objekt;
  onClose: () => void;
  ownedObjekts: Objekt[];
  selectedObjekts: Objekt[];
  setSelectedObjekts: Dispatch<SetStateAction<Objekt[]>>;
}) {
  let [objektNo, setObjektNo] = useState('');
  let [objektCount, setObjektCount] = useState<number>(0);
  let [owners, setOwners] = useState<[number, string][]>([]);
  let [transfers, setTransfers] = useState<
    | {
        from: string;
        to: string;
        blockNumber: number;
      }[]
    | null
  >(null);
  let [nicknames, setNicknames] = useState<{
    [address: string]: string;
  }>({});
  let [timestamps, setTimestamps] = useState<{
    [blockNumber: number]: number;
  }>({});

  function onToggleObjektSelection(objekt: Objekt, isSelected: boolean) {
    setSelectedObjekts(currentSelectedObjekts => {
      if (isSelected) {
        return [...currentSelectedObjekts, objekt];
      } else {
        return currentSelectedObjekts.filter(
          selectedObjekt => selectedObjekt !== objekt,
        );
      }
    });
  }

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      // TODO: On load, there are cases where count is 0 or owners is empty
      let { count, owners } = await polarisClient.getCollectionDetails(
        objekt.collectionId,
      );
      if (!isCancelled) {
        setObjektCount(count);
        if (count === 0 && !owners.length) {
          setObjektCount(owners.length);
        }
        setOwners(owners.sort((a, b) => a[0] - b[0]));
        if (!objektNo && owners.length > 0) {
          setObjektNo(owners[0][0].toString());
          loadObjektDetails(owners, owners[0][0].toString());
        }
      }
    })();
    return () => void (isCancelled = true);
  }, [objekt]);

  let [transferable, setTransferable] = useState(false);
  let [ownerAddress, setOwnerAddress] = useState<string | null>(null);
  let [ownerNickname, setOwnerNickname] = useState<string | null>(null);
  let [isLoading, setIsLoading] = useState(false);
  let [isFlipped, setIsFlipped] = useState(false);

  // FIXME: There's a bunch of race conditions here
  async function loadObjektDetails(
    owners: [number, string][],
    objektNo: string,
  ) {
    if (!objektNo) {
      return;
    }
    setIsLoading(true);
    try {
      const ownerAddress = owners.find(
        ([no, _]) => no === parseInt(objektNo),
      )?.[1];
      if (!ownerAddress) {
        console.error('Owner not found');
        setOwnerAddress(null);
        setOwnerNickname(null);
        setTransfers([]);
        // setNicknames({});
        // setTimestamps({});
        setTransferable(false);
        // TODO: handle this
        return;
      }
      setOwnerAddress(ownerAddress);
      getNicknames([ownerAddress]).then(nicknames =>
        setOwnerNickname(nicknames[ownerAddress]),
      );
      let { tokenId, transfers } = await polarisClient.getObjektDetails(
        objekt.collectionId,
        parseInt(objektNo),
      );
      if (tokenId === -1) {
        setTransfers([]);
        setTransferable(false);
        return;
      }
      let addresses = new Set<string>();
      let blockNumbers = new Set<number>();
      for (let transfer of transfers) {
        if (transfer.from !== '0x0000000000000000000000000000000000000000') {
          addresses.add(transfer.from);
        }
        addresses.add(transfer.to);
        blockNumbers.add(transfer.blockNumber);
      }
      let [transferable, nicknames, timestamps] = await Promise.all([
        cosmoClient
          .get<{
            objekt: Objekt;
          }>(`/objekt/v1/token/${tokenId}`)
          .then(({ objekt }) => objekt.transferable),
        getNicknames(Array.from(addresses)),
        getTimestamps(Array.from(blockNumbers)),
      ]);
      setTransferable(transferable);
      setTransfers(transfers);
      setNicknames(nicknames);
      setTimestamps(timestamps);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <ModalHeader>{objekt.collectionId}</ModalHeader>
      <ModalBody>
        <div className='flex flex-col sm:flex-row items-stretch gap-6'>
          <div className='w-[12rem] self-center sm:min-w-[16rem]'>
            <div
              className='relative w-full aspect-w-[1083] aspect-h-[1673] transition-transform'
              style={{
                transformStyle: 'preserve-3d',
                transform: isFlipped ? 'rotateY(180deg)' : '',
              }}
              onClick={() => setIsFlipped(!isFlipped)}
            >
              <div
                className='absolute inset-0'
                style={{ backfaceVisibility: 'hidden' }}
              >
                <img src={objekt.frontImage} alt='Objekt Image' />
              </div>
              <div
                className='absolute inset-0'
                style={{
                  backfaceVisibility: 'hidden',
                  transform: 'rotateY(180deg)',
                }}
              >
                <img src={objekt.backImage} alt='Objekt Back Image' />
              </div>
            </div>
          </div>
          <div className='flex-1 flex flex-col'>
            <div className='flex flex-wrap gap-2'>
              <Chip size='sm'>
                <span className='font-bold'>
                  {objekt.artists?.length === 1 ? 'Artist' : 'Artists'}
                </span>{' '}
                {objekt.artists?.join(', ')}
              </Chip>
              <Chip size='sm'>
                <span className='font-bold'>Season</span> {objekt.season}
              </Chip>
              <Chip size='sm'>
                <span className='font-bold'>Class</span> {objekt.class}
              </Chip>
              <Chip size='sm'>
                <span className='font-bold'>Member</span> {objekt.member}
              </Chip>
              <Chip size='sm'>
                <span className='font-bold'>Copies</span>{' '}
                {objektCount === 0 ? (
                  <Spinner
                    size='sm'
                    className='translate-y-[2px] translate-x-[2px]'
                    classNames={{ wrapper: 'w-3 h-3' }}
                    color='white'
                  />
                ) : (
                  objektCount
                )}
              </Chip>
            </div>
            <Tabs aria-label='Tabs' className='mt-4'>
              <Tab key='owned' title='Owned'>
                {/* TODO: handle scrolling */}
                {ownedObjekts.length ? (
                  <div className='flex flex-col'>
                    {ownedObjekts.map(ownedObjekt => (
                      <Checkbox
                        key={ownedObjekt.tokenId}
                        isSelected={selectedObjekts.includes(ownedObjekt)}
                        isDisabled={!ownedObjekt.transferable}
                        onValueChange={isSelected =>
                          onToggleObjektSelection(ownedObjekt, isSelected)
                        }
                      >
                        {getShortCollectionId(ownedObjekt)} #
                        {ownedObjekt.objektNo}
                      </Checkbox>
                    ))}
                  </div>
                ) : (
                  <div className='flex h-40 justify-center items-center text-foreground-400'>
                    No objekts owned.
                  </div>
                )}
              </Tab>
              <Tab key='details' title='Details'>
                <div className='flex flex-row items-center gap-3 mb-4'>
                  <Button
                    isIconOnly
                    variant='flat'
                    color='primary'
                    isLoading={isLoading}
                    isDisabled={owners.length === 0}
                    onClick={() => {
                      let currentIndex = owners.findIndex(
                        ([no, _]) => no === parseInt(objektNo),
                      );
                      let prevObjektNo: string;
                      if (currentIndex === -1) {
                        prevObjektNo = owners[0][0].toString();
                      } else if (currentIndex === 0) {
                        prevObjektNo = owners[owners.length - 1][0].toString();
                      } else {
                        prevObjektNo = owners[currentIndex - 1][0].toString();
                      }
                      setObjektNo(prevObjektNo);
                      loadObjektDetails(owners, prevObjektNo);
                    }}
                  >
                    <ArrowLeftIcon className='w-6 h-6' />
                  </Button>
                  <form
                    className='flex flex-row gap-3 flex-grow'
                    onSubmit={e => {
                      e.preventDefault();
                      loadObjektDetails(owners, objektNo);
                    }}
                  >
                    <Input
                      type='number'
                      inputMode='numeric'
                      pattern='[0-9]*'
                      placeholder='Objekt Number'
                      startContent={
                        <HashIcon className='text-foreground-500 w-6 h-6' />
                      }
                      value={objektNo}
                      onValueChange={setObjektNo}
                      classNames={{ input: 'text-base placeholder:text-small' }}
                      disabled={isLoading}
                    />
                    <Button
                      type='submit'
                      isIconOnly
                      color='primary'
                      variant='flat'
                      isLoading={isLoading}
                    >
                      <SendIcon className='w-6 h-6' />
                    </Button>
                  </form>
                  <Button
                    isIconOnly
                    variant='flat'
                    color='primary'
                    isLoading={isLoading}
                    isDisabled={owners.length === 0}
                    onClick={() => {
                      let currentIndex = owners.findIndex(
                        ([no, _]) => no === parseInt(objektNo),
                      );
                      let nextObjektNo: string;
                      if (currentIndex === -1) {
                        nextObjektNo = owners[0][0].toString();
                      } else if (currentIndex === owners.length - 1) {
                        nextObjektNo = owners[0][0].toString();
                      } else {
                        nextObjektNo = owners[currentIndex + 1][0].toString();
                      }
                      setObjektNo(nextObjektNo);
                      loadObjektDetails(owners, nextObjektNo);
                    }}
                  >
                    <ArrowRightIcon className='w-6 h-6' />
                  </Button>
                </div>
                {ownerAddress && (
                  <div className='mt-4 mb-2 flex flex-col gap-2'>
                    <div className='flex justify-between items-center'>
                      <span className='text-sm font-semibold'>Owner:</span>
                      {ownerNickname ? (
                        <Link className='text-sm' to={`/@${ownerNickname}`}>
                          {ownerNickname}
                        </Link>
                      ) : (
                        <Link className='text-sm' to={`/${ownerAddress}`}>
                          {ownerAddress}
                        </Link>
                      )}
                    </div>
                    <div className='flex justify-between items-center'>
                      <span className='text-sm font-semibold'>
                        Transferable:
                      </span>
                      <Chip
                        size='sm'
                        color={transferable ? 'success' : 'danger'}
                      >
                        {transferable ? 'Yes' : 'No'}
                      </Chip>
                    </div>
                  </div>
                )}
                {/* TODO: handle scrolling */}
                <Table
                  className='mt-4'
                  removeWrapper
                  isStriped
                  layout='fixed'
                  classNames={{ td: 'group-data-[odd=true]:before:-z-10' }}
                >
                  <TableHeader>
                    <TableColumn className='w-40'>Owner</TableColumn>
                    <TableColumn>Date</TableColumn>
                  </TableHeader>
                  <TableBody
                    emptyContent={
                      transfers === null
                        ? 'No objekt specified.'
                        : 'Not minted yet.'
                    }
                  >
                    {(transfers ?? []).map((transfer, index) => (
                      <TableRow key={index}>
                        <TableCell className='overflow-hidden text-ellipsis'>
                          {nicknames[transfer.to] ? (
                            <Link
                              className='inline'
                              to={`/@${nicknames[transfer.to]}`}
                            >
                              {nicknames[transfer.to]}
                            </Link>
                          ) : (
                            <Link className='inline' to={`/${transfer.to}`}>
                              {transfer.to}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>
                          {new Date(
                            timestamps[transfer.blockNumber] * 1000,
                          ).toLocaleString('en-US')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Tab>
            </Tabs>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='danger' onPress={onClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
}
