import 'inter-ui/inter.css';

import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';
import {
  init as initSentry,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/react';

initSentry({
  dsn: 'https://55ac6857dd124992edb028afcf22ead2@o4505666811920384.ingest.sentry.io/4506268814802944',
  integrations: [
    browserTracingIntegration({
      tracePropagationTargets: ['localhost'],
    }),
    replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

import { CredentialsProvider } from './credentials';
import Root from './routes/root';
import Gravity, { loader as gravityLoader } from './routes/gravity';
import GravityDetail, {
  loader as gravityDetailLoader,
} from './routes/gravity-detail';
import GravityCertificate from './routes/gravity-certificate';
import ObjektDetail from './routes/objekt-detail';
import Objekts, {
  ObjektsTabRoute,
  TradesTabRoute,
  loader as objektsLoader,
  objektsTabLoader,
} from './routes/objekts';

let router = createBrowserRouter([
  {
    path: '',
    element: <Root />,
    children: [
      // FIXME: I know React Router isn't expected to be used like this.
      {
        path: '',
        element: <Objekts key='my' />,
        loader: objektsLoader,
        children: [
          {
            path: '',
            element: <ObjektsTabRoute />,
            loader: objektsTabLoader,
            // To not reinitiate fetching objekts
            shouldRevalidate: ({ currentUrl, nextUrl }) => {
              if (currentUrl.pathname === nextUrl.pathname) {
                return false;
              }
              return true;
            },
            children: [
              {
                path: 'objekt/:collectionId',
                element: <ObjektDetail />,
              },
            ],
          },
          {
            path: 'wants',
            element: <ObjektsTabRoute />,
            loader: objektsTabLoader,
            // To not reinitiate fetching objekts
            shouldRevalidate: ({ currentUrl, nextUrl }) => {
              if (currentUrl.pathname === nextUrl.pathname) {
                return false;
              }
              return true;
            },
          },
          {
            path: 'trades',
            element: <TradesTabRoute />,
          },
        ],
      },
      { path: 'gravity', element: <Gravity />, loader: gravityLoader },
      {
        path: 'gravity/:gravityId',
        element: <GravityDetail />,
        loader: gravityDetailLoader,
      },
      {
        path: 'gravity/certificate',
        element: <GravityCertificate />,
      },
      {
        path: ':userId',
        element: <Objekts key='other' />,
        loader: objektsLoader,
        children: [
          {
            path: '',
            element: <ObjektsTabRoute />,
            loader: objektsTabLoader,
            shouldRevalidate: ({ currentUrl, nextUrl }) => {
              if (currentUrl.pathname === nextUrl.pathname) {
                return false;
              }
              return true;
            },
          },
          {
            path: 'wants',
            element: <ObjektsTabRoute />,
            loader: objektsTabLoader,
            shouldRevalidate: ({ currentUrl, nextUrl }) => {
              if (currentUrl.pathname === nextUrl.pathname) {
                return false;
              }
              return true;
            },
          },
          {
            path: 'haves',
            element: <ObjektsTabRoute />,
            loader: objektsTabLoader,
            shouldRevalidate: ({ currentUrl, nextUrl }) => {
              if (currentUrl.pathname === nextUrl.pathname) {
                return false;
              }
              return true;
            },
          },
          {
            path: 'trades',
            element: <TradesTabRoute />,
          },
        ],
      },
    ],
  },
]);

createRoot(document.getElementById('root')!).render(
  <NextUIProvider>
    <CredentialsProvider>
      <RouterProvider router={router} />
    </CredentialsProvider>
  </NextUIProvider>,
);
